import {useState} from "react";

import './Button.css';

function Button(props) {
    const [isActive, setIsActive] = useState(false);

    let className = "Button button main";

    // custom lass
    if (props.className) {
        className += ` ${props.className}`;
    }

    // dark class
    if (props.dark) {
        className += ` secondary`;
    }

    // back class
    if (props.submit) {
        className += ` submit`;
    }

    // disabled class
    if (props.disabled) {
        className += ` disabled`;
    }

    const disabled = props.disabled || false;
    const type = props.type || 'button';

    let style = {};
    if (props.style) {
        style = props.style;
    }
    return (
        <button onMouseDown={() => setIsActive(true)} onMouseUp={() => setIsActive(false)}
                className={className} type={type} onClick={props.onClick} disabled={disabled} style={style}>
            {props.label}
        </button>
    );
}

export default Button;