import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';

import Login from "./routes/Login";
import Navbar from "./routes/Navbar";


import Projects from "./routes/projects/Projects";
import LoggedUser from "./components/LoggedUser";
import config from "./config/config";


import UserAdd from "./routes/users/UserAdd";
import UserDetail from "./routes/users/UserDetail";
import Users from "./routes/users/Users";
import ProjectAdd from "./routes/projects/ProjectAdd";
import ProjectDetail from "./routes/projects/ProjectDetail";
import AppConfig from "./routes/appConfig/AppConfigDetail";

import './App.css';
import {useAuth} from "./context/AuthContext";
import {AxiosWrapper} from "./axios";


function App() {
    const {isLoggedIn} = useAuth();
    if (!isLoggedIn) return <Login/>;

    return (
        <div className="app">
            <AxiosWrapper/>
            <ToastContainer style={{width: "50%"}}/>
            <Router>
                <Navbar/>
                <section className="app__body">
                    <LoggedUser/>
                    <Switch>
                        <Route exact path="/" render={() => {
                            return (isLoggedIn && <Redirect to={config.routeMap.projects}/>);
                        }}/>

                        {/* index routes */}
                        <Route path={config.routeMap.projects} component={Projects}/>
                        <Route path={config.routeMap.users} component={Users}/>
                        <Route path={config.routeMap.appConfig} component={AppConfig}/>

                        {/*/!* edit routes *!/*/}
                        <Route path={config.routeMap.editProject} component={ProjectDetail}/>
                        <Route path={config.routeMap.editUser} component={UserDetail}/>

                        {/*/!* add routes *!/*/}
                        <Route path={config.routeMap.addProject} component={ProjectAdd}/>
                        <Route path={config.routeMap.addUser} component={UserAdd}/>
                    </Switch>
                </section>
                {/*<div style={{*/}
                {/*    textAlign: 'center',*/}
                {/*    backgroundColor: 'white',*/}
                {/*    marginTop: '4rem',*/}
                {/*    marginBottom: '1rem'*/}
                {/*}}> Copyright @ 2023 ChatGPT, Všechna práva vyhrazena*/}
                {/*</div>*/}
            </Router>
        </div>
    );
}

export default App;
