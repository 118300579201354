import * as React from "react";
import {useState} from "react";
import {DateRangePicker} from 'react-date-range';
import {cs} from 'react-date-range/dist/locale';
import {defaultStaticRanges, defaultInputRanges} from "./defaultRanges";
import {uniqueId} from "../../../tools";

import './DateRange.css';

function DateRange(props) {
    const [visible, setVisible] = useState(false);

    const _renderDateRange = () => {
        if (props.value?.dateRange) {
            const {startDate, endDate} = props.value.dateRange;
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
    }

    const ranges = props?.value?.dateRange || {startDate: new Date(), endDate: new Date(), key: 'dateRange'}
    const label = _renderDateRange() || props.label;
    const id = uniqueId();
    return (
        <div className="DateRangeFilter app__filter__item ico"
             onMouseLeave={() => setVisible(false)}
        >
            <label htmlFor={id}>{label}</label>
            <input type="text" id={id} onChange={props.onChange} onClick={() => setVisible(true)}/>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M160 0V64H288V0h64V64h96v96H0V64H96V0h64zM0 192H448V512H0V192zM64 320h64V256H64v64zm128-64v64h64V256H192zm192 64V256H320v64h64zM64 448h64V384H64v64zm192-64H192v64h64V384zm64 64h64V384H320v64z"/>
            </svg>
            <div className="container"
                 style={
                     {
                         display: visible ? 'block' : 'none',
                         zIndex: visible ? 3 : 0
                     }}>
                <DateRangePicker staticRanges={defaultStaticRanges}
                                 inputRanges={defaultInputRanges}
                                 locale={cs} ranges={[ranges]} onChange={props.onChange}/>
            </div>
        </div>
    );
}

export default DateRange;