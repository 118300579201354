const config = {
    routeMap: {
        loggedInHP: '/projects',
        projects: '/projects',
        users: '/users',
        appConfig: '/appConfig',

        getProjectDetail: id => `/project/${id}`,
        getUserDetail: id => `/user/${id}`,

        addProject: '/project',
        addUser: '/user',

        editProject: '/project/:id',
        editUser: '/user/:id'
    },
    apiRouteMap: {
        project: '/api/project',
        listUsers: '/api/user/list',
        signIn: '/api/user/signin',
        getUser: id => `/api/user/${id}`,
        getProject: id => `/api/project/${id}`,
        deleteProject: (id, env) => `/api/project/${id}/${env}`,
        buildDevDomain: (value, domainSuffix) => `/api/project/buildDevDomain/${value}/${domainSuffix}`,
        cloneProject: (id) => `/api/project/cloneProject/${id}`,
        appConfig: '/api/appConfig',
        renewToken: '/api/renewToken'
    },
    menuItems: {
        '/projects': ['/projects', '/project/[a-f\\d]{24}', '/project'],
        '/users': ['/users', '/user/[a-f\\d]{24}', '/user'],
        '/appConfig': ['/appConfig'],
    },
    wssConfig: {
        messageTypes: {
            NEW: 'new',
            STEP_UPDATE: 'stepUpdate',
            UPDATE_DETAIL: 'updateDetail',
            FINISHED: 'finished',
            ERROR: 'error',
            CMD: 'cmd',
            COPY_PROGRESS: 'copyProgress'
        },
        entityTypes: {
            PROJECT: 'project',
        }
    },
    getWebSocketAddress: (token) => {
        let host = window.location.host
        if (/localhost/.test(host)) {
            return `ws://127.0.0.1:3002/ws?access_token=${token}`;
        } else {
            return `wss://${host}/ws?access_token=${token}`;
        }
    }
};

export default config;