import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {onChange, prepareFormData} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";
import {mergeObjects} from "../tools";

import './UserForm.css';
import {useAuth} from "../context/AuthContext";

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    _id: null,
    name: '',
    surname: '',
    email: '',
    profileList: [],
    password: '',
    passwordAgain: ''
}

function UserForm(props) {
    const {getDecodedToken} = useAuth();
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [sendResetPasswordEmail, setSendResetPasswordEmail] = useState(true);

    useEffect(() => {
        if (props.data) {
            const data = mergeObjects(INITIAL_FORM_DATA, props.data);
            setFormData(data);
        }
    }, [props.data]);


    const onSubmit = async () => {
        try {
            setIsLoading(true);
            let data = prepareFormData({...formData});

            const id = data._id;
            if (id) {
                delete data.password;
                delete data.passwordAgain;
                await authService.put(config.apiRouteMap.getUser(id), data);
            } else {
                if (!sendResetPasswordEmail) {
                    if (!formData.password.length || !formData.passwordAgain.length) {
                        throw t('passwordCantBeEmpty');
                    } else if (formData.password !== formData.passwordAgain) {
                        throw t('passwordDoesNotMatch');
                    }
                }

                data.sendResetPasswordEmail = sendResetPasswordEmail;
                await authService.post(config.apiRouteMap.user, data);
            }

            setError(null);
            setIsLoading(false);
            props.history.goBack();
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const onDelete = async () => {
        try {
            setIsLoading(true);
            await authService.delete(config.apiRouteMap.getUser(formData._id));
            setError(null);
            setIsLoading(false);
            props.history.push(config.routeMap.users);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const onChangeProfiles = event => {
        let newProfileList = formData.profileList;

        if (event.target.checked) {
            newProfileList.push(event.target.name);
        } else {
            newProfileList = newProfileList.filter(profile => profile !== event.target.name);
        }
        setFormData(prevState => ({
                ...prevState,
                profileList: newProfileList
            }
        ));
    }

    const isDeleteAllowed = () => {
        const decodedToken = getDecodedToken();
        return decodedToken._id !== formData._id && decodedToken.profileList.indexOf("SuperAdmin") !== -1;
    }

    const renderUserProfilesForm = () => {
        if (authService.isSuperAdmin()) {
            return (
                <React.Fragment>
                    <Forms.Checkbox label={t('superAdmin')} name="SuperAdmin" onChange={onChangeProfiles}
                                    value={formData.profileList.indexOf('SuperAdmin') !== -1}/>
                </React.Fragment>
            );
        }
    }

    const renderSendResetPasswordCheckbox = () => {
        if (!formData._id) {
            return <Forms.Checkbox label={t('sendResetPasswordEmail')} value={sendResetPasswordEmail}
                                   onChange={e => setSendResetPasswordEmail(e.target.checked)}/>;
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <Forms.Text label={t('firstName')} value={formData.name} name='name'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Text label={t('surname')} value={formData.surname} name='surname'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Text label={t('email')} value={formData.email} name='email'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            {
                                !formData._id &&
                                <Forms.Text label={t('password')} value={formData.password} name='password'
                                            disabled={isLoading} onChange={onChange(setFormData)}/>
                            }
                            {
                                !formData._id &&
                                <Forms.Text label={t('passwordAgain')} value={formData.passwordAgain}
                                            name='passwordAgain'
                                            disabled={isLoading} onChange={onChange(setFormData)}/>
                            }
                            {renderUserProfilesForm()}
                            {renderSendResetPasswordCheckbox()}
                        </div>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <div className="actionsGroup">
                        <Forms.Button label={t('goBack')} onClick={props.history.goBack} disabled={isLoading}/>
                        {isDeleteAllowed() &&
                        <Forms.Button label={t('delete')} onClick={onDelete} disabled={isLoading}/>}
                    </div>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="UserForm app__box app__box--wide">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default UserForm;