import React, {useEffect, useState} from 'react';
import jwtDecode from "jwt-decode";
import AuthService from "../AuthService";
import Loading from "./Loading";
import {ReactComponent as LogoutIcon} from '../img/svg/ico-logout.svg';
import t from "../hooks/useTranslation";

import './LoggedUser.css';
import {useAuth} from "../context/AuthContext";

const authService = new AuthService();

function LoggedUser() {
    const {getToken, logout} = useAuth();
    const [user, setUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.get(`/api/user/${jwtDecode(getToken())._id}`);
                setUser(data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
            }
        })();
    }, []);

    const renderContent = () => {
        return (
            <div className="app__controls">
                <div className="app__controls__user">
                    <span>
                        <strong>{`${user.name} ${user.surname}`}</strong><br/>{user.email}
                    </span>
                </div>
                <div className="app__controls__touch logout">
                    <LogoutIcon onClick={logout}/>
                </div>
                <div className="app__controls__menu">
                    <button type="button" aria-controls="nav" aria-label="Zobrazit navigaci" data-open="Menu"
                            data-close="Zavřít" onClick={console.log} id="nav-toggle">
                        <span className="burger"><span/></span>
                        <span className="stamp">{t('menu')}</span>
                    </button>
                </div>
            </div>
        );
    }

    const getContent = () => {
        return isLoading ? <Loading/> : renderContent();
    }

    return (
        <div className="LoggedUser">
            {getContent()}
        </div>
    );
}

export default LoggedUser;