import Text from './Text';
import DateRange from "./DateRange";
import Boolean from "./Boolean";
import Select from "./Select";
import "./OverviewTableFilters.css";

const OverviewTableFilters = {};

OverviewTableFilters.Text = Text;
OverviewTableFilters.DateRange = DateRange;
OverviewTableFilters.Boolean = Boolean;
OverviewTableFilters.Select = Select;

export default OverviewTableFilters;