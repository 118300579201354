import * as React from "react";
import './ColorPicker.css';
import {BlockPicker, SketchPicker} from "react-color";

const inputMainProps = ['required', 'name', 'value', 'placeholder', 'min', 'max'];

function ColorPicker(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const handleOnChange = data => {
        props.onChange({
            target: {
                name: props.name,
                value: data.hex
            }
        });
    }

    const disabled = props.disabled || false;
    return (
        <label className="FormColorPicker">
            {props.label}
            <BlockPicker {...getMainProps(inputMainProps)} disabled={disabled}
                          onChange={handleOnChange}/>
        </label>
    );
}

export default ColorPicker;