import * as React from "react";
import {NavLink, useLocation} from "react-router-dom";
import config from "../config/config";
import t from "../hooks/useTranslation";
import AuthService from "../AuthService";
import {ReactComponent as ProjectsIcon} from '../img/svg/ico-regaly.svg';
import {ReactComponent as Logo} from '../logo.svg';
import logo from '../img/logo.png';

import './Navbar.css';

const authService = new AuthService();

function Navbar() {
    const {pathname} = useLocation();

    const isActive = (route) => {
        return config.menuItems[route]?.some(regex => {
            return pathname.match(`^${regex}$`);
        });
    }

    const menuItem = (label, route, icon, profileList) => {
        // zkontrolujeme, že uživatel má profil, který je definovaný v profileList
        if (profileList) {
            const userProfiles = authService.getUserProfileList();
            const hasAccess = profileList.some(validProfile => userProfiles.indexOf(validProfile) !== -1);
            if (!hasAccess) {
                return null;
            }
        }

        return (
            <li>
                <NavLink to={route} activeClassName='selected' isActive={() => isActive(route)}>
                    {icon}
                    <span>{label}</span>
                </NavLink>
            </li>
        );
    }

    return (
        <aside className="app__navbar" id="nav">
            <div className="logo logo--white">
                <Logo/>
            </div>
            <nav className="app__nav">
                <ul>
                    {menuItem(t('projects'), config.routeMap.projects, <ProjectsIcon alt="menu-icon"/>)}
                    {menuItem(t('users'), config.routeMap.users, <ProjectsIcon alt="menu-icon"/>)}
                    {menuItem(t('appConfig'), config.routeMap.appConfig, <ProjectsIcon alt="menu-icon"/>)}
                </ul>
            </nav>
        </aside>
    );
}

export default Navbar;
