import * as React from "react";
import './Number.css';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'onKeyPress', 'min'];

function Number(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;

    const colWidth = props.colWidth || 50;
    let className = `FormInput col-${colWidth}`;
    props.className && (className = `${className} ${props.className}`);
    props.required && (className += " required");
    return (
        <label className={className}>
            <label>{props.label}</label>
            <input type="number" {...getMainProps(inputMainProps)} disabled={disabled}/>
        </label>
    );
}

export default Number;