import * as React from "react";

import './File.css';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'accept'];

function File(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    return (
        <label className="FormFile">
            {props.label}
            <input type="file" {...getMainProps(inputMainProps)} disabled={disabled}/>
        </label>
    );
}

export default File;