import React, {useEffect, useRef, useState} from 'react';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Forms from "../../form/Forms";
import {inputsContainer, onChange} from "../../tools";
import AuthService from "../../AuthService";
import config from "../../config/config";
import t from "../../hooks/useTranslation";
import {useParams} from "react-router-dom";
import {toast} from 'react-toastify';
import useWebSocket from 'react-use-websocket';
import {ReactComponent as DownloadDbIcon} from '../../img/svg/download-db.svg';
import Modal from "../../components/Modal";
import StartProdModalContent from "./StartProdModalContent";
import {useAuth} from "../../context/AuthContext";
import {axiosInstance} from "../../axios";
import EditHtaccessModalContent from "./EditHtaccessModalContent";

import './ProjectForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    name: '',
    domainSuffix: 'cz',
    dev: {
        domain: '',
        createdAt: '',
        editedAt: '',
        createdBy: '',
        dirPath: '',
        db: {
            name: '',
            user: '',
            password: ''
        }
    },
    noteList: []
}

function ProjectForm(props) {
    let {id} = useParams();
    const {getToken} = useAuth();
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [htaccessEnv, setHtaccessEnv] = useState();
    const [validationErrors, setValidationErrors] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const buildTimeOut = useRef(null);
    const [updateDetailLogs, setUpdateDetailLogs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showHtaccessModal, setShowHtaccessModal] = useState(false);
    const [urlConflict, setUrlConflict] = useState(false);
    const [first, setFirst] = useState(true);
    const {sendJsonMessage} = useWebSocket(config.getWebSocketAddress(getToken()), {
        shouldReconnect: () => true,
        onMessage: message => processMessage(message)
    });

    useEffect(() => {
        if (props.data) {
            setFormData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (!id && formData.name !== '') {
            clearTimeout(buildTimeOut.current);
            buildTimeOut.current = setTimeout(async () => {
                let url;
                setUrlConflict(false);
                try {
                    const {data} = await authService.get(config.apiRouteMap.buildDevDomain(formData.name, formData.domainSuffix));
                    if (data?.url) {
                        url = data.url;
                    }
                } catch (e) {
                    if (e.response.status === 409) {
                        url = e.response.data.url;
                        setUrlConflict(true);
                    } else {
                        toast.error(<pre>{JSON.stringify(e.response.data, null, 2)}</pre>, {
                            position: "top-center",
                            theme: "dark"
                        });
                    }
                }

                // fill url
                const tmp = {...formData};
                tmp.dev.domain = url;
                setFormData(tmp);
            }, 300);
        }
    }, [formData.name, formData.domainSuffix]);

    const onSubmit = async () => {
        try {
            let validationErrors = [];
            setValidationErrors([]);
            setIsLoading(true);
            let project = {...formData};

            // check for errors
            if (validationErrors.length) {
                setValidationErrors(validationErrors);
            } else {
                sendJsonMessage({controller: 'ProjectController.createProject', body: project});
                setError(null);
            }

        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const onProdSubmit = async ({domain, domainSuffix}) => {
        setUpdateDetailLogs([]);
        setShowModal(false);
        const projectData = {
            domain,
            domainSuffix,
            env: 'prod',
            projectId: id
        };

        try {
            let validationErrors = [];
            setValidationErrors([]);
            setIsLoading(true);

            // check for errors
            if (validationErrors.length) {
                setValidationErrors(validationErrors);
            } else {
                try {
                    sendJsonMessage({controller: 'ProjectController.cloneProject', body: projectData});
                } catch (e) {
                    setError(e);
                } finally {
                    setIsLoading(false);
                }
            }

        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const processUpdateLog = (message, color = 'white') => {
        const logs = [...updateDetailLogs];
        logs.unshift(<label style={{color}}>{message}</label>);
        setUpdateDetailLogs(logs);
    }

    const processCopyLog = (message, color = 'white') => {
        const logs = [...updateDetailLogs];
        if (first) {
            setFirst(false);
            logs.unshift(<label style={{color}}>{message}</label>);
        } else {
            logs[0] = <label style={{color}}>{message}</label>;
        }
        setUpdateDetailLogs(logs);
    }

    const processMessage = (message) => {
        message = JSON.parse(message.data);
        if (message.entity === config.wssConfig.entityTypes.PROJECT) {
            switch (message.type) {
                case config.wssConfig.messageTypes.FINISHED:
                    toast.success(t('projectSuccessfullyCreated'), {position: "top-center", theme: "dark"});
                    break;
                case config.wssConfig.messageTypes.STEP_UPDATE:
                    toast.info(message.data.message, {
                        position: "top-center",
                        theme: "dark"
                    });
                    break;
                case config.wssConfig.messageTypes.ERROR:
                    toast.error(<pre>{JSON.stringify(message.data.errors, null, 2)}</pre>, {
                        position: "top-center",
                        theme: "dark"
                    });
                    break;
                case config.wssConfig.messageTypes.CMD:
                    processUpdateLog(message.data, 'green');
                    break;
                case config.wssConfig.messageTypes.COPY_PROGRESS:
                    processCopyLog(message.data, 'yellow');
                    break;
                default:
                    processUpdateLog(message.data.stderr || message.data.stdout)
            }
        }
    }

    const deleteProject = async () => {
        if (window.confirm(t('confirmDelete'))) {
            setIsLoading(true);
            try {
                await authService.delete(config.apiRouteMap.deleteProject(id, 'dev'));
                toast.success(t('projectDeletedSuccessfully'), {position: "top-center", theme: "dark"});
                props.history.push(config.routeMap.projects);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const domainOptions = [
        {label: 'cz', value: 'cz'},
        {label: 'com', value: 'com'},
        {label: 'en', value: 'en'},
        {label: 'sk', value: 'sk'},
        {label: 'eu', value: 'eu'},
        {label: 'film', value: 'film'},
        {label: 'no', value: 'no'},
    ];

    const downloadDb = (env) => {
        return async () => {
            axiosInstance({
                url: `/api/project/${formData._id}/downloadDb/${env}`,
                method: 'GET',
                responseType: 'blob'
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${formData[env].domain}.sql`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error('Chyba při stahování souboru:', error);
                    toast.error(error);
                });
        }
    }

    const generateDkimKeys = () => {
        axiosInstance({
            url: `/api/project/${formData._id}/generateDkim`,
            method: 'POST'
        })
            .then(({data}) => {
                setFormData(data);
            })
            .catch((error) => {
                console.error('Chyba při generování certifikátů:', error);
                toast.error(error);
            });
    }

    const onHtaccessSubmit = () => {
        alert();
    }

    const handleShowHtaccess = (env) => {
        setHtaccessEnv(env);
        setShowHtaccessModal(true);
    }

    const downloadKey = (type) => {
        axiosInstance({
            url: `/api/project/${formData._id}/downloadDkimKey/${type}`,
            method: 'POST',
            responseType: 'blob'
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${formData.prod.domain}_${type}.pem`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error('Chyba při generování certifikátů:', error);
                toast.error(error);
            });
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <Modal show={showModal} onClose={() => setShowModal(false)} onSubmit={onProdSubmit}>
                        <StartProdModalContent domainOptions={domainOptions}/>
                    </Modal>
                    <Modal show={showHtaccessModal} onClose={() => setShowHtaccessModal(false)}
                           onSubmit={onHtaccessSubmit}>
                        <EditHtaccessModalContent project={formData} env={htaccessEnv}/>
                    </Modal>
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <Forms.Text label={t('name')} value={formData.name} name='name' required
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Select required label={t('domainSuffix')} value={formData.domainSuffix}
                                          name='domainSuffix' disabled={id || isLoading}
                                          onChange={onChange(setFormData)} options={domainOptions}/>
                        </div>

                        <Forms.NestedForm name='dev' disabled={isLoading} value={formData.dev}
                                          onChange={onChange(setFormData)} className="grid" label={t('devConfig')}>
                            <Forms.Text required note={urlConflict && "Tato URL je již obsazena"}
                                        className={urlConflict && 'url-conflict'} name='domain'
                                        label={t('devDomain')} disabled={id || isLoading}/>
                            <Forms.NestedForm inputContainer={inputsContainer} name="db"
                                              label={<>{t('dbDevConfig')}<DownloadDbIcon title={t('downloadDb')}
                                                                                         className="download-icon"
                                                                                         onClick={downloadDb('dev')}/></>}
                                              desc={!formData.dev?.db?.name && t('dbCanBeEmpty')}>
                                <Forms.Text disabled={id || isLoading} name="name" label={t('dbName')}/>
                                <Forms.Text disabled={id || isLoading} name="user" label={t('dbUser')}/>
                                <Forms.Text disabled={id || isLoading} name="password" label={t('dbPassword')}/>
                            </Forms.NestedForm>
                        </Forms.NestedForm>

                        {formData.prod &&

                        <Forms.NestedForm name='prod' disabled={isLoading} value={formData.prod}
                                          onChange={onChange(setFormData)} className="grid" label={t('prodConfig')}>
                            <Forms.Text required name='domain' label={t('prodDomain')} disabled={id || isLoading}/>
                            <Forms.NestedForm inputContainer={inputsContainer} name="db"
                                              label={<>{t('dbProdConfig')}<DownloadDbIcon title={t('downloadDb')}
                                                                                          className="download-icon"
                                                                                          onClick={downloadDb('prod')}/></>}
                                              desc={!formData.prod?.db?.name && t('dbCanBeEmpty')}>
                                <Forms.Text disabled={id || isLoading} name="name" label={t('dbName')}/>
                                <Forms.Text disabled={id || isLoading} name="user" label={t('dbUser')}/>
                                <Forms.Text disabled={id || isLoading} name="password" label={t('dbPassword')}/>
                            </Forms.NestedForm>
                        </Forms.NestedForm>}

                        {
                            formData?.prod?.dkim &&
                            <div className="dkimKeys">
                                <div>
                                    <h4>DKIM klíče</h4>
                                    <div className="divider"/>
                                </div>
                                <Forms.Button label="Stáhnout privátní klíč" onClick={() => downloadKey("private")}/>
                                <Forms.Button label="Stáhnout veřejný klíč" onClick={() => downloadKey("public")}/>
                            </div>
                        }
                    </form>
                </div>
                {error && <Error error={error}/>}
                {validationErrors && validationErrors.map(error => <p className="validation-error">{error}</p>)}
                <div className="formActions">
                    <div>
                        <Forms.Button label={t('goBack')} onClick={props.history.goBack} disabled={isLoading}/>
                        {id && <Forms.Button label={t('delete')} dark onClick={deleteProject} disabled={isLoading}/>}
                        {id && <Forms.Button label={t('editHtaccess')} dark onClick={() => handleShowHtaccess("dev")}
                                             disabled={isLoading}/>}
                        {id && formData.prod &&
                        <Forms.Button label="Vygenerovat DKIM klíče" dark onClick={generateDkimKeys}
                                      disabled={isLoading}/>}
                    </div>
                    {!id && <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>}
                    {id && !formData.prod &&
                    <Forms.Button label={t('createProd')} onClick={() => setShowModal(true)} disabled={isLoading}/>}
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <React.Fragment>
            <div className="ProjectContainer">
                {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
            </div>
            {
                (updateDetailLogs.length > 0) &&
                <div className="logContainer">{React.Children.toArray(updateDetailLogs)}</div>
            }
        </React.Fragment>
    );
}

export default ProjectForm;