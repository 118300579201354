import React, {useEffect, useState} from "react";
import Forms from "../../form/Forms";
import Button from "../../form/Button";
import t from "../../hooks/useTranslation";
import {onChange} from "../../tools";
import {axiosInstance} from "../../axios";
import {toast} from 'react-toastify';
import "./EditHtaccessModalContent.css";


function EditHtaccessModalContent({closeModal, onSubmit, project, env}) {
    const [formData, setFormData] = useState();

    useEffect(() => {
        const init = async () => {
            try {
                const {data} = await axiosInstance.get(`/api/project/${project._id}/loadHtaccess/${env}`);
                setFormData(data);
            } catch (e) {
                console.log(e);
                toast.error(<pre>{JSON.stringify(e.response.data, null, 2)}</pre>, {
                    position: "top-center",
                    theme: "dark"
                });
            }
        }

        init();
    }, []);
    return (
        <div className="EditHtaccessModalContent">
            <div className="body">
                <div>
                    <Forms.Textarea label={t('htaccess')} name="htaccess" onChange={onChange(setFormData)}
                                    value={formData}/>
                </div>
            </div>
            <div className="footer">
                <Button onClick={closeModal} label={t("close")} dark/>
                <Button onClick={() => onSubmit(formData)} label={t("save")}/>
            </div>
        </div>
    );
}

export default EditHtaccessModalContent;