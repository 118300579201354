import * as React from "react";
import Overview from "../../components/OverviewTable/Overview";
import t from "../../hooks/useTranslation";
import AppConfigForm from "./AppConfigForm";

import './AppConfigDetail.css';

function ProjectDetail(props) {

    return (
        <div className="AppConfigDetail app__main">
            <Overview title={t('appConfigDetail')}>
                <AppConfigForm history={props.history}/>
            </Overview>
        </div>
    );
}

export default ProjectDetail;
