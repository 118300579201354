import * as React from "react";

import Overview from "../../components/OverviewTable/Overview";
import t from "../../hooks/useTranslation";
import ProjectForm from "./ProjectForm";

import './ProjectAdd.css';

function ProjectAdd(props) {

    return (
        <div className="Project app__main">
            <Overview title={t('newProject')}>
                <ProjectForm history={props.history}/>
            </Overview>
        </div>
    );
}

export default ProjectAdd;
