import * as React from "react";
import config from "../../config/config";
import {Link} from "react-router-dom";
import useOverviewData from "../../hooks/useOverviewData";
import Overview from "../../components/OverviewTable/Overview";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import t from "../../hooks/useTranslation";
import moment from "moment";

import './Projects.css';

function Projects() {
    const {
        isLoading,
        error,
        response,
        setFilter,
        handleOrderChange,
        order,
        filter,
        bricksData
    } = useOverviewData(config.apiRouteMap.project, 100);

    const handleFilterChange = (key, value, type) => {
        let newFilter = {...filter};
        newFilter[key] = {type, value};
        if (
            (type === "text" && value === "")
            || (type === "select" && value === undefined)
        ) {
            delete newFilter[key];
        }
        setFilter(newFilter);
    };

    const columns = [
        {
            key: 'name',
            title: t('name'),
            linkRenderer: (data) => ({
                route: config.routeMap.getProjectDetail(data._id),
                label: data.name
            }),
            filter: {
                type: 'text',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('name')
            },
            order: true
        },
        {
            key: "dev.domain",
            title: t('devDomain'),
            valueRenderer: ({dev}) => dev?.domain || '',
            filter: {
                type: 'text',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('devDomain')
            },
            order: true
        },
        {
            key: "prod.domain",
            title: t('prodDomain'),
            valueRenderer: ({prod}) => prod?.domain || '',
            filter: {
                type: 'text',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('prodDomain')
            },
            order: true
        },
        {
            key: 'dev.createdAt',
            title: t('devCreatedAt'),
            valueRenderer: ({createdAt}) => moment(createdAt).format('DD.MM.YYYY HH:mm'),
            order: true
        },
        {
            key: 'prod.createdAt',
            title: t('prodCreatedAt'),
            valueRenderer: (project) => project?.prod?.createdAt ? moment(project.prod.createdAt).format('DD.MM.YYYY HH:mm') : '',
            order: true
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="button secondary small" to={config.routeMap.getProjectDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [
        {
            component: <Link className="button secondary" to={config.routeMap.addProject}>{t('newProject')}</Link>
        }
    ];

    return (
        <div className="Projects app__main">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('shelfAdministration')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('projectList')} columns={columns} order={order}
                               rows={response?.list} rowClassNameRenderer={item => item.prod && "finished"}
                               handleOrderChange={handleOrderChange}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Projects;
