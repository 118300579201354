import React, {useEffect, useState} from 'react';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Forms from "../../form/Forms";
import {onChange} from "../../tools";
import t from "../../hooks/useTranslation";
import config from "../../config/config";
import AuthService from "../../AuthService";
import {toast} from "react-toastify";
import "./AppConfigForm.css";

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    projectNotificationEmailList: []
}

function ProjectForm(props) {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [validationErrors, setValidationErrors] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            const {data} = await authService.get(config.apiRouteMap.appConfig);
            setFormData(data);
        }
        loadData()
    }, []);

    const onSubmit = async () => {
        try {
            let validationErrors = [];
            setValidationErrors([]);
            setIsLoading(true);

            // check for errors
            if (validationErrors.length) {
                setValidationErrors(validationErrors);
            } else {
                await authService.put(config.apiRouteMap.appConfig, formData)
                setError(null);
                toast.success(t('changesSaved'), {position: "top-center", theme: "dark"});
            }

        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">

                        <div className="row">
                            <Forms.Checkbox label={t('sendEmailNotifications')} name="sendEmailNotifications"
                                            value={formData.sendEmailNotifications} onChange={onChange(setFormData)}/>
                        </div>

                        <div className="row">
                            <Forms.List name="projectNotificationEmailList"
                                        value={formData.projectNotificationEmailList}
                                        label={t('notificationEmails')}
                                        onChange={onChange(setFormData)}/>
                        </div>

                    </form>
                </div>
                {error && <Error error={error}/>}
                {validationErrors && validationErrors.map(error => <p className="validation-error">{error}</p>)}
                <div className="formActions">
                    <div>
                        <Forms.Button label={t('goBack')} onClick={props.history.goBack} disabled={isLoading}/>
                    </div>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <React.Fragment>
            <div className="AppConfigForm app__box">
                {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
            </div>
        </React.Fragment>
    );
}

export default ProjectForm;