import React, {useEffect, useState} from "react";
import Forms from "../../form/Forms";
import Button from "../../form/Button";
import t from "../../hooks/useTranslation";
import {onChange} from "../../tools";

import "./StartProdModalContent.css";

function StartProdModalContent({closeModal, onSubmit, domainOptions}) {
    const [formData, setFormData] = useState({domainSuffix: 'cz'});

    useEffect(() => {
        console.log("load");
    }, []);
    return (
        <div className="StartProdModalContent">
            <div className="body">
                <div className="domain-row">
                    <Forms.Text label={t('typeProdDomain')} name="domain" onChange={onChange(setFormData)}
                                rows={10} value={formData.domain}/>
                    <Forms.Select label={t('domainSuffix')} name="domainSuffix" onChange={onChange(setFormData)}
                                  options={domainOptions} value={formData.domainSuffix}/>
                </div>
            </div>
            <div className="footer">
                <Button onClick={closeModal} label={t("close")} dark/>
                <Button onClick={() => onSubmit(formData)} label={t("save")}/>
            </div>
        </div>
    );
}

export default StartProdModalContent;