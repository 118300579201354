import config from "./config/config";
import jwtDecode from 'jwt-decode';
import {axiosInstance} from "./axios";

class AuthService {
    // Initializing important variables
    constructor() {
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.login = this.login.bind(this);
    }

    async login(body) {
        // Get a token from api server using the fetch api
        try {
            return await this.post(config.apiRouteMap.signIn, body);
        } catch (e) {
            throw e;
        }
    }

    async forgottenPassword(email) {
        // Get a token from api server using the fetch api
        try {
            const response = await this.post('/api/resetPassword', {email});
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async setNewPassword(id, token, password, passwordAgain) {
        // Get a token from api server using the fetch api
        try {
            const {data} = await this.post('/api/setNewPassword', {
                id,
                token,
                password,
                passwordAgain
            });
            return data;
        } catch (e) {
            throw e;
        }
    }

    logout() {
        localStorage.clear();
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // Getting token from localstorage
        if (token && this.isTokenExpired(token)) {
            // logout and redirect
            localStorage.clear();
            window.location.href = "/";
            return false;
        }
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    isSuperAdmin() {
        // Checks if user is cast in submitted profile
        if (this.loggedIn()) {
            const profileList = this.getUserProfileList();
            return profileList.indexOf("SuperAdmin") !== -1;
        }
        return false;
    }

    isTokenExpired(token) {
        try {
            const decoded = jwtDecode(token);
            return new Date(decoded.exp * 1000) < Date.now(); // Checking if token is expired. N
        } catch (err) {
            return false;
        }
    }

    getDecodedToken() {
        const token = this.getToken(); // Getting token from localstorage
        if (token && !this.isTokenExpired(token)) {
            return jwtDecode(token);
        }
    }

    getToken() {
        return localStorage.getItem('accessToken');
    }

    getUserProfileList() {
        return localStorage.getItem('profileList').split(',');
    }

    fetch(url, method = 'GET', formData = null, headers = null) {
        const options = {
            url,
            method,
            headers: {},
            params: formData
        };
        if (this.loggedIn()) {
            options.headers['Authorization'] = `Bearer ${this.getToken()}`;
        }
        if (headers) {
            Object.entries(headers).forEach(([key, val]) => {
                options.headers[key] = val;
            });
        }

        return axiosInstance(options);
    }

    async post(url, formData, headers = {}, onUploadProgress = null) {
        try {
            const config = {
                headers: {},
                onUploadProgress
            }

            if (this.loggedIn()) {
                config.headers['Authorization'] = `Bearer ${this.getToken()}`;
            }
            if (headers) {
                Object.entries(headers).forEach(([key, val]) => {
                    config.headers[key] = val;
                });
            }
            return await axiosInstance.post(url, formData, config);
        } catch (err) {
            throw err;
        }
    }

    async put(url, formData, headers = {}, onUploadProgress = null) {
        try {
            const config = {
                headers: {},
                onUploadProgress
            }

            if (this.loggedIn()) {
                config.headers['Authorization'] = `Bearer ${this.getToken()}`;
            }
            if (headers) {
                Object.entries(headers).forEach(([key, val]) => {
                    config.headers[key] = val;
                });
            }
            await axiosInstance.put(url, formData, config);
        } catch (err) {
            throw err;
        }
    }

    async delete(url) {
        try {
            return await this.fetch(url, 'DELETE');
        } catch (err) {
            throw err;
        }
    }

    async get(url) {
        try {
            return await this.fetch(url, 'GET');
        } catch (err) {
            throw err;
        }
    }

}

export default AuthService;