export const objectId = function () {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

export const onChange = (setFormData) => (e, key, val) => {
    let value;
    let name;
    if (e) {
        name = e.target.name;
        switch (e.target.type) {
            case 'checkbox':
                value = e.target.checked;
                break;
            case 'number':
                value = e.target.value ? parseFloat(e.target.value) : '';
                break;
            case 'file':
                value = e.target.files;
                break;
            default:
                value = e.target.value;
        }
    } else {
        value = val;
        name = key;
    }
    setFormData(prevState => ({
            ...prevState,
            [name]: value
        }
    ));
}

export const mergeObjects = (obj1, obj2) => {
    const tmp = {};
    Object.entries(obj1).forEach(([key, val]) => {
        // is object
        if (!Array.isArray(val) && typeof val === 'object' && val !== null) {
            if (obj2.hasOwnProperty(key)) {
                tmp[key] = mergeObjects(val, obj2[key]);
            } else {
                tmp[key] = obj1[key];
            }
        } else if (Array.isArray(val)) {
            tmp[key] = obj2.hasOwnProperty(key) ? obj2[key] : obj1[key];
        } else {
            tmp[key] = obj2.hasOwnProperty(key) ? obj2[key] : obj1[key];
        }
    });
    return tmp;
}

export const prepareFormData = formData => {
    const newData = {};
    Object.entries(formData).forEach(([key, val]) => {
        if (val && val !== "") {
            newData[key] = val;
        }
    });
    return newData;
}

export const uniqueId = (prefix = 'id-') => prefix + Math.random().toString(16).slice(-8);

export const inputsContainer = content => <div className="row">{content}</div>;