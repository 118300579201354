import React from 'react';
import OverviewBrick from "./OverviewBrick";
import Error from "../Error";

import './Overview.css';

function Overview(props) {

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className="app__welcome">{props.introduction}</div>
                <div className="app__title"><h1>{props.title}{getWsStatusIcon()}</h1></div>
                {
                    props.bricks &&
                    <section className="app__bricks">
                        {
                            props.bricks.map((bricksData, i) => <OverviewBrick key={`overviewBrick${i}`}
                                                                               data={bricksData}/>)
                        }
                    </section>
                }
                {props.error && <Error error={props.error}/>}
            </React.Fragment>
        );
    }

    function getWsStatusIcon() {
        if (props.wsStatus) {
            const statusColor = {
                "Connecting": "#ff870e",
                "Open": "#11c500",
            }[props.wsStatus];
            return <span title={`WebSocket status: ${props.wsStatus}`} className="wsStatus"
                         style={{backgroundColor: statusColor || "#ff0e0e"}}/>;
        }
    }

    return React.Children.toArray([
        renderContent(),
        props.children
    ]);
}

export default Overview;